.certificate-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card h3 {
  margin: 0 0 8px;
}

.card p {
  margin: 0 0 16px;
}

.card button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.card button:hover {
  background-color: #0056b3;
}

.close-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.close-button:hover {
  background-color: #cc0000;
}

/* Modal customization */
.ReactModal__Overlay {
  z-index: 1000;
}

.ReactModal__Content {
  overflow: hidden;
  padding: 20px;
  background-color: #363636;
  border: none;
  border-radius: 8px;
}

#cerCard{
  padding: 5%;
  width: 35vh;
  height: 300px;
}
#cercard *{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limit the text to 3 lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

#pcerCard{
  padding: 5%;
  width: 100vh;
}
#pcercard *{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limit the text to 3 lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}


@media only screen and (max-width: 1300px){
  #cerCard{
    width: 30vh;
  }
  #pcerCard{
    padding: 5%;
    width: 100%;
  }
}


@media only screen and (max-width: 915px){
  #cerCard{
    width: 25vh;
  }
}



@media only screen and (max-width: 550px){
  #cerCard{
    width: 40vh;
  }
}

@media only screen and (max-width: 350px){
  #cerCard{
    width: 30vh;
  }
}

@media only screen and (max-width: 260px){
  #cerCard{
    width: 20vh;
  }
}