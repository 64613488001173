.suggestions-list {
    border: none;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    width: auto;
    background-color: #212121;
    z-index: 500;
    padding: 0;
    margin: 0;
    list-style: none;
}

.suggestions-list li {
    padding: 10px;
    cursor: pointer;
    color: white;
}

.suggestions-list li:hover {
    background-color: #333333;
}
