/* INTRODUCTION */
#about-design{
    color: rgba(255, 255, 255, 0.95);
    background: linear-gradient(#212121, #212121) padding-box,
                linear-gradient(145deg, transparent 20%,#5f06a2, #097caa) border-box;
    border: 2px solid transparent;
    border-radius: 8px;
    transform-origin: right bottom;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    margin-bottom: 1%; 
    overflow:hidden;
    box-sizing: border-box;
}
#about-design h2{
    font-weight: bold;
}
.about {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 25% 25% auto auto;
    padding-bottom: 20px;
}
@media only screen and (max-width: 500px) {
    .about{
        padding-bottom: 30px;
    }
}
@media only screen and (max-width: 335px) {
    .about{
        padding-bottom: 60px;
    }
}
@media only screen and (max-width: 270px) {
    .about{
        padding-bottom: 110px;
    }
}
@media only screen and (max-width: 220px) {
    .about{
        padding-bottom: 200px;
    }

}
@media only screen and (max-width:180px){
    .about-content {
        overflow: hidden;
    }
    .about{
        padding-bottom: 10px;
    }
}
.aboutHeading {
    grid-area: 1 / 1 / 3 / -1;
    background-image: url("back-intro.jpg");
    background-size: cover;
    background-repeat:no-repeat;
    background-position: center;
    height: 25vh;
}
.aboutHeading h2 {
    margin: 0;
    padding: 0;
    font-size: 1.7rem;
    margin-top: 1rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    overflow: hidden;
    text-decoration: underline;
    color: white;
}
.about-image {
    z-index: 2;
    grid-area: 2 / 2 / 4 / 2;
    margin: 0;
    padding: 0;
    margin-top: -30px;
    margin-bottom: 0;
}
.about-image img {
    width: 25vh;
    height: 25vh;
    border:2px solid rgba(206, 206, 206, 0.9);
    border-radius: 100%;
    display: block;
    margin: 0 auto;
    overflow-clip-margin: content-box;
    overflow: clip;
    object-fit: cover;
    justify-content: center;
}
.about-image img:hover{
    transform: scale(1.1);
}

.about-content {
    text-align: center;
    grid-area: 4 / 1 / -1 / -1;
    margin: 2% 20px 0 20px;
    padding: 10px;
    border: 1px solid;
    height: auto;
}

.about-content p {
    font-size: 0.9rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    word-wrap: break-word;

}

.about-content h3{
    font-weight: bold;
    text-decoration: underline;
}

/* EDUCATION */
        /* MAIN */
#education{
    margin-top: 10px;
    margin-bottom: 30px;
}
#education h2 {
    font-size: 1.7rem;
    text-decoration:underline;
    margin: 0;
    color: white;
}

@media only screen and (max-width: 400px){
    #education h2 {
        padding-top: 20%;
    }
}

@media only screen and (max-width: 230px){
    #education h2 {
        padding-top: 60%;
    }
}

#education h3{
    justify-content: center;
    margin: 1vh 20%;
    border-bottom: 1px solid rgb(175, 176, 177);
    font-style: italic;
}
.school{
    display: flex;
    justify-content: space-evenly;
    margin: 0;
}
.school p{
    opacity: 0.7;
}
.school .ab-size-img{
    height: min(100px) max(200px);
    width: min(100px) max(200px);
    overflow: hidden;
}
.edu *{
    margin: 0;
}
.edu{
    margin: 10px 20px;
}
.edu:hover{
    transition: 0.5s;
    transform: scale(1.1);
}
.school img{
    height: 100px;
    width: 100px;
    object-fit: cover;
    opacity: 0.8;
    overflow: hidden;
}
.school img:hover{
    opacity: 1;
}

            /* SECUNDARY */
.sec-edu{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0;
}
.sec-edu p{
    opacity: 0.7;
}
.sec-edu .ab-size-img{
    height: min(100px) max(200px);
    width: min(100px) max(200px);
    overflow: hidden;
}
.sec-edu img{
    height: 100px;
    width: 100px;
    object-fit: cover;
    opacity: 0.8;
    overflow: hidden;
}
.sec-edu img:hover{
    opacity: 1;
}

/* SKILLS */
#skills{
    margin-top: 10px;
}
#skills h2 {
    font-size: 1.7rem;
    text-decoration:underline;
    margin: 0 0 20px 0;
    color: white;
}
.skill-div{
    margin: 0 5%;
    display:grid;
    grid-template-columns: 40% 30% 30%;
    grid-template-rows: auto auto;
}
@media only screen and (max-width:660px){
    .skill-div{
        margin:0;
    }
}
.skill-div h3{
    margin: 0 0 10px 0;
}
.about-el{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.about-el img{
    margin: 3px;
    border-radius: 10px;
    height: 30px;
}
.about-el img:hover{
    transition: 0.4s;
    transform: scale(1.2);
}
.tech-stack{
    grid-row: 1/3;
    border: 1px solid white;
    padding: 5%;
}
.tech-tools{
    grid-column: 2/4;
    border: 1px solid white;
    padding: 5%;
}
.design{
    border: 1px solid white;
    padding: 5%;
}
.framework{
    border: 1px solid white;
    padding: 5%;
}

/* CERTIFICATES */
#certificates{
    margin-top: 10px;
}
#certificates h2 {
    font-size: 1.7rem;
    text-decoration:underline;
    margin: 0;
    color: white;
}
#certificates h3 {
    font-size: 0.8rem;
    margin-top: 5px;
    font-style: italic;
}
.cert-div{
    display: flex;
    flex-wrap: wrap;
    margin: 2%;
}

.pdf-summary {
    cursor: pointer;
    margin: 1%;
    color: rgba(255, 255, 255, 0.95);
    background: linear-gradient(#212121, #212121) padding-box,
                linear-gradient(145deg, transparent 20%,#5f06a2, #097caa) border-box;
    border: 2px solid transparent;
    border-radius: 8px;
    transform-origin: right bottom;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    margin-bottom: 1%; 
    overflow:hidden;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: auto auto;
    width: 48%;
}
@media only screen and (max-width:660px){
    .pdf-summary{
        width: 100%;
    }
    .pdf-summary .ab-size-img img{
        max-height: 100px;
        max-width: 100px;
    }
}
.pdf-summary:hover{
    background-color: rgb(77, 77, 77);
    transition: 0.4s;
    transform: scale(1.01);
}
        /* INSIDE ELEMENTS */
.pdf-summary .ab-size-img{
    grid-row: 1/3;
    grid-column: 1/2;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    min-height: 50px;
}
.pdf-summary .ab-size-img img{
    height: 100%;
    width: 100%;
    min-width: 50px;
    min-height: 50px;
    max-height: 80px;
    padding: 0;
    opacity: 0.8;
}
.pdf-summary .ab-size-img img:hover{
    opacity: 1;
}
#certificates .cert-div .pdf-summary h2{
    margin: 0;
    padding: 0;
    grid-column: 2/3;
    font-size: 1rem;
    text-decoration: none;
    margin: 10px 10px 0 0;
}
.pdf-summary p{
    margin: 0;
    font-size: 0.7rem;
    font-style: italic;
    margin: 0 10px 10px 0;
    justify-content: center;
}

        /*  DISPLAY PDFS */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: rgba(54, 54, 54, 1);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    height: 80%;
    overflow: auto;
}

.pdf-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
#pdf-frame {
    width: 100%; 
    height: 100%;
}