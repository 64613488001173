/* MEDIA */
@media only screen and (max-width: 848px) {
    #topbar-title {
        display: block !important;
    }
    #sidebar-trigger, #search-trigger {
        display: block !important;
    }
    #breadcrumb {
        display: none;
    }
    #search-wrapper{
        display: none !important;
    }
}
/* TOPBAR */
#topbar-title {
    display: none;
    font-size: 1.1rem;
    font-weight: 600;
    font-family: sans-serif;
    color: rgb(175, 176, 177);
    text-align: center;
    width: 100%;
    margin-left: 5%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
    margin-right: 2%;
    z-index: 1;
}
@media only screen and (max-width: 768px) {
    #topbar-title {
        margin-left: 40px;
    }
}
@media only screen and (max-width: 380px) {
    #topbar-title {
        margin-left: 30px;
    }
}

#topbar-wrapper {
    height: 4rem;
    background-color: rgb(27, 27, 30, 0.64);
    overflow: hidden;
}
#topbar i {
    color: #999;
}

        /* BREADCRUMB */
#topbar #breadcrumb {
    font-size: 1rem;
    color: gray;
    padding-left: 0.5rem;
}

/* BREADCRUMB */

#breadcrumb{
    margin-right: 10%;
    margin: 0;
    padding: 0;
}
/* EFFECTS */
#breadcrumb span{
    color:#999;
    opacity: 0.6;
    font-size:20px;
}
#breadcrumb span:hover{
    color: rgba(255, 255, 255, 0.95);
    font-size:25px;
    transition: all 300ms;
    opacity: 1;
}
#sidebar-trigger{
    margin-right: 5%;
    margin-left: 2%;
    z-index: 100;
    position: fixed;
    overflow: hidden;
}
#sidebar-trigger:hover{
    color:rgba(255, 255, 255, 0.95);
    opacity: 1;
    transform: scale(1.6);
    transition: all 300ms;
}
#search-trigger{
    margin-right: 2%;
    overflow: hidden;
}
#search-trigger:hover{
    color:rgba(255, 255, 255, 0.95);
    opacity: 1;
    transform: scale(1.6);
    transition: all 300ms;
}

/* SEARCH */

#sidebar-trigger, #search-trigger {
    display: none;
}

#search-wrapper {
    display: flex;
    width: 30%;
    border-radius: 1rem;
    border: 1px solid rgb(55, 55, 55);
    background: rgb(27, 27, 30);
    padding: 0 0.5rem;
}

#search-wrapper i {
    z-index: 2;
    font-size: .9rem;
    color: rgb(100, 102, 105);
}
#long-search-wrapper {
    display: flex;
    width: 30%;
    border-radius: 1rem;
    border: 1px solid rgb(55, 55, 55);
    background: rgb(27, 27, 30);
    padding: 0 0.5rem;
}

#long-search-wrapper i {
    z-index: 2;
    font-size: .9rem;
    color: rgb(100, 102, 105);
}

#search-input {
    background: center;
    border: 0;
    border-radius: 0;
    padding: 0.18rem 0.3rem;
    color: rgb(175, 176, 177);
    height: auto;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

[type=search] {
    outline-offset: -2px;
}
#search-cancel {
    color: rgb(138, 180, 248);
    margin-left: 1rem;
    display: none;
}
.post-tail-wrapper .share-wrapper .share-icons>i, #search-cancel, .code-header button {
    cursor: pointer;
}

/* BEHAVE - INPUT*/
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
input:not([type="range"]):not([type="color"]) {
    writing-mode: horizontal-tb;
}
input[type="search" i] {
    appearance: auto;
    box-sizing: border-box;
    padding: 1px 2px;
}
/* input:not([type="file" i], [type="image" i], [type="checkbox" i], [type="radio" i]) {

} */
input {
    /* font-style: ;
    font-variant-ligatures: ;
    font-variant-caps: ;
    font-variant-numeric: ;
    font-variant-east-asian: ;
    font-variant-alternates: ;
    font-weight: ;
    font-stretch: ;
    font-size: ;
    font-family: ;
    font-optical-sizing: ;
    font-kerning: ;
    font-feature-settings: ;
    font-variation-settings: ; */
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    background-color: field;
    margin: 0em;
    padding: 1px 2px;
    border-width: 2px;
    border-style: inset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
}