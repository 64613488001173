/* SCROLL BAR */
::-webkit-scrollbar {
  width: 10px;
}
      /* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgb(54, 54, 54, 1);
}
 
      /* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #5f06a2, #097caa);
  border-radius: 10px;
} 

/* UNIVERSAL STYLING */
*{
  font-size: 16px;
  scroll-behavior: smooth;
  box-sizing: border-box;
  /* border: 1px solid green; */
}

*, ::after, ::before {
  box-sizing: border-box;
}

body {
  background-color: rgb(27, 27, 30);
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: rgb(175, 176, 177);
  -webkit-font-smoothing: antialiased;
  font-family: "Source Sans Pro","Microsoft Yahei",sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align:center;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  display: block;
}

/* TAG GENERAL STYLING --------------------------------------------------------------------*/
div {
  display: block;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

a {
  text-decoration: none;
  color: rgb(175, 176, 177);
  text-decoration: none;
}

.active *{
  color: white !important;
  opacity: 1;
}

i {
  font-style: italic;
}
h1 {
  font-size: 1.92rem;
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
/* GENERAL STYLING */
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-center {
  align-items: center;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
.ps-0 {
  padding-left: 0;
}
.flex-grow-1 {
  flex-grow: 1;
}
.w-100 {
  width: 100%;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.h-100 {
  height: 100% ;
}
.hidden{
  display: none;
}

/* MEDIA GENERAL*/
@media only screen and (max-width: 848px){
#main-wrapper {
  width: 100%;
  margin-left: 0 !important;
  padding: 0;
}
.container{
  margin: 0;
  padding: 0 !important;
}

}

/* MAIN WRAPPER ---------------------------------------------------------------------------------------------------*/
#main-wrapper {
  background-color: /* rgb(63, 65, 68); */ rgb(27, 27, 30);
  position: relative;
  min-height: calc(100vh - 2rem);
  padding-left: 0;
  padding-right: 0;
  margin-left: 300px;
  margin-right: 0;
  width: 100% - 300px;
  margin-right: 2%;
  margin-left: 300px;
  padding-left: 2%;
}

      /* CONATINER */
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}
/* SEARCH GENERAL STANDARD STYLE-------------------------------- */
#search-results a, #search-hints .post-tag, a {
  color: rgb(138, 180, 248);
}
#search-results a, h5, h4, h3, h2, h1 {
  color: #cccccc;
  font-weight: 400;
  font-family: Lato,"Microsoft Yahei",sans-serif;
}
/* MEDIA */
@media only screen and (min-width: 1200px){
.h1, h1 {
  font-size: 2.5rem;
}}
/* GENERAL FOR STYLING INDEX ---------------------------------------------------------------------------------------- */

/* CORE WRAPPER ----------------------------------------------------------------*/
#main_page{
  box-sizing: border-box;
  display: flex;
  padding: 0;
  flex-shrink: 0;
  width: 100%;
  /* border: 1px solid white; */
}

#core-wrapper {
  line-height: 1.75;
  width: 75%;
  margin: 0;
  padding: 0;
  margin-right: 1rem;
}

/* MIX GENERAL TOPBAR+MAIN PAGE */
#topbar-wrapper#main_page, #main>#main_page, #search-result-wrapper>#main_page {
  margin-left: 0;
  margin-right: 0;
}

/* MEDIA */
@media only screen and (max-width: 1199px){
#main>div#main_page {
  justify-content: center;
}
#core-wrapper{
  width: 100%;
  margin-right: 0;
}
#main_page{
  margin-left: -1% !important;
}
}



/* PANEL WRAPPER ----------------------------------------------------------------*/
#panel-wrapper {
  width: 25%;
  padding-left: 1rem;
  border-left: 1px solid rgb(44, 45, 45);

  /* border: 1px solid green; */
}

.text-muted {
  color: rgb(107, 116, 124);
}

/* TAGS CONTROL */
.access:only-child {
  position: sticky;
}
.access {
  top: 2rem;
  transition: top .2s ease-in-out;
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.access>div {
  padding-left: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.95);
}
#panel-wrapper .panel-heading {
  color: rgba(255, 255, 255, 0.95);
  font-size:20px;
  font-weight: 600;
  margin:0;
  margin-left: -15px;
  padding: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}
.me-3 {
  margin-right: 1rem;
}
.mt-3 {
  margin-top: 1rem;
}
/* POST TAGS */

.tags a{
  color: rgba(84, 83, 83, 1);
}
#panel-wrapper-content{
  position: fixed;
}
#panel-wrapper .post-tag {
  line-height: 1.05rem;
  font-size: .85rem;
  border: 1px solid rgb(63, 65, 68);
  border-radius: 0.8rem;
  padding: 0.3rem 0.5rem;
  margin: 0 0.35rem 0.5rem 0;
}
#panel-wrapper .post-tag:hover {
  background-color: rgba(54, 54, 54, 1);
  color: rgb(152, 149, 149);
}

.post-tag:not(:last-child) {
  margin-right: 0.2rem;
}
.post-tag {
  display: inline-block;
  min-width: 2rem;
  text-align: center;
  border-radius: 0.3rem;
  padding: 0 0.4rem;
  color: inherit;
  line-height: 1.3rem;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/* MUCIS */
.music{
  margin-top: 3%; 
  padding-right: 4%;
}
.music iframe{
  width: 100%;
  height: 50vh;
}

/* MEDIA */
@media (max-width: 1199px){
#panel-wrapper {
  display: none;
}
}

/* SECUNDARY NAVIGATION */
.sec-nav a{
  width: 70%;
}
