/* TITLE */
#posts h1 {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  text-align: start;
  overflow: hidden;
}

/* POSTS */
.post-card {
  width: 100%;
  height: 10%; 
  color: rgba(255, 255, 255, 0.95);
  background: linear-gradient(#212121, #212121) padding-box,
              linear-gradient(145deg, transparent 35%,#5f06a2, #097caa) border-box;
  border: 2px solid transparent;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
  transform-origin: right bottom;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  padding: 0;
  margin-bottom: 1%; 
  overflow:hidden;
}
.post-card .post-main-content{
  width: 60%;
  height: auto;
  margin: 2%;
  margin-right: 0;
  text-align: start;
}
.post-card .post-image{
width: 40%;
height: auto + 1%;
margin: 0;
padding: 0;
}

.post-card .post-image img{
height: 100%;
object-fit: cover;
width: 100%;
}

.post-main-content .post-text{
text-align: justify;
margin: 0 4% 0 0;
}
.post-main-content .post-text p{
font-size: 1rem;
max-height: auto; /* Set the maximum height to 60px */
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 3; /* Limit the text to 3 lines */
-webkit-box-orient: vertical;
text-overflow: ellipsis;
}

.post-card .post-header span:first-child {
  font-weight: 600;
  color: #717171;
  margin-right: 4px;
}

.post-card .post-heading {
  font-size: 24px;
  margin: 0;
  font-weight: 600;
}

.post-card .post-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: end;
  padding-right: 2%;
}

.post-card .post-categories span {
  background: linear-gradient(145deg, transparent 30%, #5f06a2, #097caa);
  padding: 4px 8px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 50em;
}
.post-card:hover {
  background-color: rgb(54, 54, 54, 1);
}

/* MEDIA */
@media only screen and (max-width: 550px){

}
    /* MIN_DIV_2 */
@media only screen and (max-width: 1600px) and (min-width: 1200px) {
.post-card .post-main-content{
  width: 50%;
  height: auto;
  margin: 2%;
  margin-right: 0;
  text-align: start;
}
.post-card .post-image{
width: 50%;
height: auto + 1%;
margin: 0;
padding: 0;
}
}

@media only screen and (max-width: 800px){
/* GENERAL */
.post-card {
  width: 100%;
  height: 10%; 
  display: block;
  margin-bottom: 2%; 
}
.post-card .post-main-content{
  width: 100%;
  height: auto;
  min-height: 100px;
  margin: 2%;
  padding-right: 2%;
  flex-wrap: wrap-reverse;
}
/* TEXT */
  .post-card .post-heading {
    font-size: 21px;
  }
  
  .post-card .post-categories span {
    font-size: 12px;
    margin: 1px;
    margin-top: 2px;
  }

/* IMAGE */
.post-card .post-image{
width: 100%;
height: auto;
margin: 0;
padding: 0;
}

.post-card .post-image img{
min-height: 150px;
max-height: 200px;
object-fit: cover;
width: 100%;
}

}