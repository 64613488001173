/* Carousel.css */

.slideshow-container {
    position: relative;
    max-width: 100%;
    margin: auto;
  }
  .slideshow-container img{
    height: 20rem;
    object-fit: cover;
  }

  
  .mySlides {
    display: none;
  }
  
  .mySlides.active {
    display: block;
  }
  
  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }
  
  @keyframes fade {
    from { opacity: .4; }
    to { opacity: 1; }
  }
  
  .numbertext {
    position: absolute;
    top: 8px;
    left: 16px;
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    background-color: rgba(0,0,0,0.5);
  }
  
  .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
    /* background-color: rgba(0,0,0,0.5); */
  }
  
  .dot-bar {
    text-align: center;
    position: absolute;
    width: 100%;
    padding: 20px;
  }
  
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  .dot.active {
    background-color: #717171;
  }
  
  #car_controls a {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }
  
  #car_controls a.prev {
    left: 0;
    border-radius: 3px 0 0 3px;
  }
  
  #car_controls a.next {
    right: 0;
    border-radius: 0 3px 3px 0;
  }
  
  #car_controls a:hover {
    background-color: rgba(0,0,0,0.8);
  }
  