/* CONTENT */
.proj-content{
    overflow: hidden;
}
#posts .proj-content .projTitle{
    text-align: center !important;
    font-size: 2rem;
    font-weight: bold;
    color: white;
    margin: 0 10% 10px 10%;
    border-bottom: 1px solid rgb(175, 176, 177);
}
#posts .proj-content h4{
    text-align: left;
    font-size: 0.8rem;
    font-style: italic;
    margin: 0;
}
#posts .proj-content h2{
    text-align: center;
    text-decoration: underline;
    margin: 0;
}
#posts .quote p{
    margin: 5%;
    font-size: 1.2rem;
    font-style: italic;
    border: 1px solid white;
}
#posts .projText {
    text-align: justify;
}
#posts .projText a{
    color: rgb(99, 19, 146);
}
#posts .text-compress{
    text-align: justify;
    margin: 3%;
}
.proj-box-div-2{
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}
.proj-box-div-2 .proj-box-pt1, .proj-box-div-2 .proj-box-pt2{
    width: 50%;
    border: 1px solid white;
}
@media only screen and (max-width:350px){
    .proj-box-div-2 .proj-box-pt1, .proj-box-div-2 .proj-box-pt2{
        width: 100%;
    }
}
/* IMAGES */
@keyframes show {
    from{
        opacity: 0;
        scale:25%;
    }
    to{
        opacity: 1;
        scale:100%;
    }
}
.proj-content-img img{
    /* view-timeline-name: --revealing-image; */
    animation-name: show;
    animation-fill-mode:both;
    animation-timeline: --revealing-image;
   /*  animation-range: entry 25% cover 50%; */
    border-radius: 20px;
    max-height: 500px;
    min-height: 200px;
    object-fit: cover;
}
.proj-content-img .proj-img-ver{
    max-height: 500px;
    min-height: 300px;
}
.proj-content-img{
    margin: 1% 0;
}
.proj-content-img .proj-3img{
    display:flex;
    justify-content: space-around;
    margin:3%;
}
.proj-content-img .proj-3img img{
    width: 30%;
    height: 300px;
    min-height: 200px;
    object-fit: cover;
}
.projActions{
    background: linear-gradient(#212121, #212121) padding-box,
                linear-gradient(145deg,#5f06a2, #097caa) border-box;
    border: 2px solid transparent;
    border-radius: 8px;
    display: flex;
    cursor: pointer;
    transform-origin: right bottom;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    justify-content: center;
    margin:2%;
    padding: 10px;
}
.projActions:hover{
    background-color: rgb(54, 54, 54, 1);
    transition: 0.4s;
    transform: scale(1.02);
}
.projActions a{
    color: rgba(255, 255, 255, 0.7);
    width: 100%;
}
.projFooter{
    background: radial-gradient(circle, #242424 0%, #1d1f27 100%);
    margin: 0 0 2% 0;
    padding: 20px;
}
@media only screen and (max-width:200px){
    .projFooter{
        padding: 0;
    }
    #posts .proj-content .projTitle{
        margin: 0;
    }
}
.projFooter a{
    color: rgb(99, 19, 146);
}